import React from 'react';
import PropTypes from 'prop-types';
import {statsUiTexts} from 'data/ui-texts';
import './stats-login.scss';

const StatsLogin = ({isLoggingIn, email, password, feedback, handleInput, handleLogin}) => {

	return (
		<div className="StatsLogin">
			<div className="StatsLogin-container">
				<div className="StatsLogin-title"><span>{statsUiTexts.loginTitle}</span></div>
				<form className="StatsLogin-form" onSubmit={handleLogin}>
					<input
						name="email"
						type="text"
						placeholder={statsUiTexts.email} 
						autoComplete="section-adminlogin email"
						value={email ? email : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						name="password"
						type="password"
						placeholder={statsUiTexts.password}
						autoComplete="section-adminlogin password"
						value={password ? password : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<input 
						className={'Button Button--login' + (isLoggingIn ? ' Button--isLoading' : '')}
						type="submit"
						value={statsUiTexts.login} 
					/>
					<p className="StatsLogin-errorMessage"><span>{feedback}</span></p> 
				</form>			
			</div>
		</div>
	);
};

StatsLogin.propTypes = {
	isLoggingIn: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	handleLogin: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired	
};

export default StatsLogin;